body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px;
  min-height: 600px;
}

/* TODO consolidate popup dimensions */
html,
body,
#root {
  min-width: 375px;
  min-height: 600px;
  height: 100%;
}

@supports (height: 100vh) {
  html,
  body,
  #root {
    height: 100vh !important;
  }
}

/* TODO consolidate popup dimensions */
@media screen and (max-height: 700px) {
  html,
  body,
  #root {
    max-height: 100%;
  }
}

/* TODO consolidate popup dimensions */
@media screen and (max-width: 500px) {
  html,
  body,
  #root {
    max-width: 100%;
  }
  html::-webkit-scrollbar,
  body::-webkit-scrollbar,
  #root::-webkit-scrollbar {
    width: 1px;
    background: transparent;
  }
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}
